<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Chart Of Accounts">
        <ChartOfAccounts></ChartOfAccounts>
      </vs-tab>
      <vs-tab label="Cost Center">
        <CostCenter></CostCenter>
      </vs-tab>
      <vs-tab label="Currency">
        <Currency></Currency>
      </vs-tab>
      <vs-tab label="Payment Method">
        <PaymentMethod></PaymentMethod>
      </vs-tab>
      <vs-tab label="Reasoon">
        <Reason></Reason>
      </vs-tab>
      <vs-tab label="Bank">
        <Bank></Bank>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ChartOfAccounts from "./chart-of-account/index.vue"
import CostCenter from "./cost-center/index.vue"
import Currency from "./currency/index.vue"
import PaymentMethod from "./payment-method/index.vue"
import Reason from "./reason/index.vue"
import Bank from "./bank/index.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    ChartOfAccounts,
    CostCenter,
    Currency,
    PaymentMethod,
    Reason,
    Bank,
  },
  data() {
    return {
      title: "Company",
    };
  },
};
</script>
